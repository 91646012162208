import { OrderType } from "../types/OrderType";
import { OrderStatusEventType } from '../types/OrderStatusDataLayerEventType';

export class OrderStatusDataLayerAPI {

  //** Build a data layer body for the order status action. */
  public logOrderStatusEvent(order: OrderType){
    const body: OrderStatusEventType = {
      event: "order_status",
      order_id: order.id,
      order_status: order.status
    }
    this.logEvent(body)
  }

  /** Add an "event" object to datalayer[]. */
  public logEvent(event: OrderStatusEventType) {
    if (window) {
      window.dataLayer = window.dataLayer || {}
      window.dataLayer.push(event)
    }
  }
}
