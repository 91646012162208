import React from 'react'
import { useEffect, useContext, useRef } from 'react'

import { parseISO, differenceInDays } from 'date-fns'
import { OrderStatusDataLayerAPI } from '../../../api/OrderStatusDataLayerAPI'
import { browserName, browserVersion, osName, isMobileOnly, isTablet } from 'react-device-detect';
import { GoogleDataLayerAPI } from '../../../api/GoogleDataLayerAPI';
import { DataLayerAPI, VisitsData } from '../../../api/DataLayerAPI'
import { CartContext, CartContextShape } from '../../../contexts/CartContext';
import { DataLayerContext, DataLayerContextShape } from '../../../contexts/DataLayerContext'
import { SessionContext, SessionContextShape } from '../../../contexts/SessionContext'
import { ABContext, ABContextShape } from '../../../contexts/ABContext'
import { DataLayerEventType } from '../../../types/DataLayerEventType'
import { ProductType } from '../../../types/ProductType'
import { OrderType } from '../../../types/OrderType'
import { ukToIsoDate } from '../../../utils/dateUtils'
import { HotelListType, Room } from '../../../types/HotelType';

type PageViewProps = {
  pageName: string,
  subCategory1?: string,
  subCategory2?: string,
  subCategory3?: string,
  productsOnPage?: ProductType[],
  hotelsOnPage?: HotelListType[],
  roomsOnPage?: Room[],
  error?: boolean,
  order?: OrderType,
}

// Add to dataLayer property to Window: https://stackoverflow.com/a/12709880/1094466
declare global {
  interface Window {
    digitalData: any;
    dataLayer: any;
  }
}

const dataLayerApi = new DataLayerAPI()
const googleDataLayerApi = new GoogleDataLayerAPI()
const orderStatusDataLayerApi = new OrderStatusDataLayerAPI()

/**
 * A component for adding a page view to the data layer. Always returns null.
 */
export function PageView({
  pageName,
  subCategory1 = '',
  subCategory2 = '',
  subCategory3 = '',
  productsOnPage = undefined,
  hotelsOnPage = undefined,
  roomsOnPage = undefined,
  order = undefined,
  error = false,
}: PageViewProps) {

  const dataLayerContext = useRef(useContext<DataLayerContextShape>(DataLayerContext))

  const cartContext = useContext<CartContextShape>(CartContext)
  const sessionContext = useContext<SessionContextShape>(SessionContext)

  const abContext = useContext<ABContextShape>(ABContext)

  const currency = 'GBP'
  const language = 'en-GB'
  const geoRegion = 'GB'

  useEffect(() => {

    window.digitalData = window.digitalData || {};
    window.dataLayer = window.dataLayer || [];

    const destinationUrl = window.location.href
    const cleanUrl = window.location.origin + window.location.pathname
    const pageID = window.location.pathname.substring(1)
    const pageInstanceID = pageID + '-' + (process.env.NODE_ENV === 'development' ? 'DEV' : 'PROD')
    const queryParams = new URLSearchParams(window.location.search)
    const responsiveState = isTablet ? 'tablet' : isMobileOnly ? 'mobile' : 'desktop'

    const match = window.location.hostname.match(/[a-z0-9_-]+\.[a-z]+$/)
    const domain = match ? match[0] : window.location.hostname
    const subdomain = window.location.hostname

    const internalReferrerDomain = dataLayerApi.getInternalReferrerDomain(dataLayerContext.current.referer)
    const referrerPageName = internalReferrerDomain ? dataLayerApi.getPreviousPageName() : ''
    dataLayerApi.logPreviousPageName(pageName)

    dataLayerApi.logVisit()
    const visitData: VisitsData | undefined = dataLayerApi.getVisitData()

    if (pageName === 'Cart') {
      dataLayerApi.logCartView()
    }

    if(error){
      const errorLog: DataLayerEventType = {
        category: {
          primaryCategory: "Error Page Category",
          subCategory1: '',
        },
        eventInfo: {
          effect: cleanUrl,
          eventAction: "Error",
          eventName: pageName,
          eventPoints: '',
          eventTargetUrl: '',
          timeStamp: new Date().toISOString(),
          type: "Page",
        }
      }
      dataLayerApi.logEvent(errorLog)
    }

    const page: any = {
      pageInstanceID,
      pageInfo: {
        pageID,
        pageName,
        destinationUrl,
        language,
        referringURL: dataLayerContext.current.referer,
        queryParameters: Array.from(queryParams.entries()).map(row => ({ key: row[0], value: row[1] })),
        sysenv: responsiveState, // desktop/tablet/mobile,
        geoRegion,
      },
      category: {
        pageType: "Commerce",
        primaryCategory: "Web Store",
      },
      attributes: {
        cleanUrl,
        responsiveState,
        scrollPercent: 0, // Will be zero on page load
        domain,
        subdomain,
        businessObjective: pageName,
        contentFocus: "Web Store",
        destination: 'UOR',
        internalReferrerDomain,
        referrerPageName,
        referrerContentFocus: 'UK Store',
        ddlCreationTimestamp: new Date().toISOString(),
      }
    }

    if (subCategory1) page.category.subCategory1 = subCategory1
    if (subCategory2) page.category.subCategory2 = subCategory2
    if (subCategory3) page.category.subCategory3 = subCategory3

    const platform = {
      version: [
        { technologyName: 'React', technologyVersion: React.version }
      ],
      platformBrowser: {
        name: browserName,
        os: osName,
        version: browserVersion,
      },
      platformType: responsiveState,
      sessionNumber: visitData?.numVisits,
      userAgent: navigator.userAgent,
    }

    const products: any = []
    if (productsOnPage) {
      productsOnPage.forEach(product => {
        product.tickets.forEach(ticket => {
          products.push({
            productInfo: {
              productID: ticket.ticket_id,
              productName: ticket.title,
              description: '', // Empty for now
              productURL: '', // Empty for now
              productImage: '', // Empty for now
              productThumbnail: '', // Empty for now
              manufacturer: '', // Empty for now
              sku: ticket.sku,
              plu: ticket.sku,
              source: 'UK Store',
              promo: 'F',
            },
            category: {
              primaryCategory: dataLayerApi.getTicketPrimaryCategory(ticket.title, ticket.attributes.map(a => a.attribute_id.substring(a.attribute_id.lastIndexOf('-')+1))),
            }
          })
        })
      })
    }

    if (hotelsOnPage) {
      hotelsOnPage.forEach(hotel => {
        products.push({
          productInfo: {
            productID: hotel.id,
            productName: hotel.title,
            description: '', // Empty for now
            productURL: '', // Empty for now
            productImage: '', // Empty for now
            productThumbnail: '', // Empty for now
            manufacturer: '', // Empty for now
            // sku: ticket.sku, TBC if needed on index page
            // plu: ticket.sku, TBC if needed on index page
            source: 'UK Store',
            promo: 'F',
          },
          category: {
            primaryCategory: 'Hotels',
          }
        });
      });
    }

    if (roomsOnPage) {
      roomsOnPage.forEach(rooms => {
        rooms.rooms.forEach(room => {
          const roomId = Object.keys(room.packages)[0];
          const roomDeatils = room.packages[roomId];
          products.push({
            productInfo: {
              productID: room.product_id,
              productName: room.title,
              description: '', // Empty for now
              productURL: '', // Empty for now
              productImage: '', // Empty for now
              productThumbnail: '', // Empty for now
              manufacturer: '', // Empty for now
              sku: roomDeatils.sku,
              plu: roomDeatils.sku,
              source: 'UK Store',
              promo: 'F',
            },
            category: {
              primaryCategory: 'Hotels',
            }
          });
        });
      });
    }

    if(pageName === 'Checkout complete' && order) {

      // Count total number of tickets in order:
      let totalQty = 0
      order.order_tickets.forEach(ticket => totalQty += parseInt(ticket.qty))

      const transaction: any = {
        transactionId: order.id,
        //profile: {
        //  profileInfo: '', // profile Id should go here
        //  address: {
        //    stateProvince: order.billing_address_street1 + " " + order.billing_address_street2, // Billing State of Guest
        //    postalCode: order.billing_address_postal_code, // Billing Postal Code of Guest
        //    countryCode: order.billing_address_country_code, // Billing Country Code of Guest
        //  },
        //},
        price: {
          basePrice: parseFloat(order.total).toFixed(2), // Amount of total transaction before taxes, discounts, and shipping (e.g. $125.99)
          voucherCode: '', // FOR FUTURE USE (empty for now) - OLTS Only right now (url or transaction)
          voucherDiscount: '', // FOR FUTURE USE (empty for now)
          currency, // Currency Code (ISO 4217) for site/transaction (e.g. USD, GBP)
          taxRate: '0.00', // Tax rate applicable to transaction in decimal form (e.g. 6.5% = .065)
          shipping: '0.00', // Total for shipping (e.g. $9.99)
          shippingMethod: '', // Shipping method selected (e.g. USPS, UPS, FedEx)
          priceWithTax: parseFloat(order.total).toFixed(2), // basePrice + (basePrice*taxRate)
          cartTotal: parseFloat(order.total).toFixed(2), // basePrice + (basePrice*taxRate)
          //transactionTotal: parseFloat(order.total).toFixed(2), // Amount of total transaction after taxes, discounts, and shipping (e.g. $155.99)
          //totalQty: order.order_tickets.length, // Total number of items in entire transaction (e.g. 5)
        },
        attributes: {
          //creditCardType: order.order_payments[0].method, // Credit Card Type used for transaction (e.g. Visa, Mastercard, American Express, Discover, etc.)
// @todo:
          //numOfDaysToArrival: '', // dateSelectedIn - Todays Date
          //resortRooms: 0, //  Number of resort rooms purchased
          //bookingEngine: 'UK Store', // Identifier for booking engine used (e.g. ICE, OLTS, OLS)
          //totalGuests: 0, // Number of Guests added at time of transaction
          //paymentType: '', // Type of payment used (use either "paid in full" or "deposit only")
          totalQty, // "Total number of items in entire cart (e.g. 5)"
          totalViews: dataLayerApi.getNumCartViews(),
          lastViewed: dataLayerApi.getLastCartViewDate()?.toISOString() ?? '',
          deliveryMethod: '',
        },
        item: [],
      }

      let adultsQty: number[] = [];
      let childrenQty: number[] = [];
      let infantsQty: number[] = [];
      let dateSelectedIn = ''
      let dateSelectedOut = ''
      let dateSelectedRange = ''

      order.order_tickets.forEach(ticket => {
        let year = ''
        if (typeof ticket.date_id !== 'undefined') {
          year = ticket.date_id.substring(0,4)
        }
        else {
          year = ticket.year
        }

        const attributeIds: string[] = []
        ticket.attributes.forEach(att => {
          if (att.attribute_id.match(/-5$/)) dateSelectedIn = ukToIsoDate(att.values[0])
          if (att.attribute_id.match(/-42$/)) dateSelectedOut = ukToIsoDate(att.values[0])
          attributeIds.push(att.attribute_id.split("-").reverse()[0])
        })
        if (dateSelectedIn && dateSelectedOut) {
          dateSelectedRange = differenceInDays(parseISO(dateSelectedOut), parseISO(dateSelectedIn)).toString()
        }

        // if (!year && dateSelectedIn) year = dateSelectedIn.substring(0, 4)

        transaction.item.push({
          productInfo: {
            productID: ticket.ticket_id,
            productName: ticket.title,
            description: '', // Empty for now
            productURL: '', // Empty for now
            productImage: '', // Empty for now
            productThumbnail: '', // Empty for now
            manufacturer: '', // Empty for now
            sku: ticket.sku,
            plu: '', // Empty for now
            source: 'UK Store',
            promo: 0,
          },
          category: {
            primaryCategory: dataLayerApi.getTicketPrimaryCategory(ticket.title, attributeIds),
          },
          attributes: {
            packageID: '', // Empty for now
            packageInstanceID: '', // Empty for now
            productAttributes: {}, // Empty for now
            Qty: ticket.qty,
            YearSelected: year,
            ...(dateSelectedIn && {dateSelectedIn, dateSelectedOut, dateSelectedRange})
          },
          price: {
            basePrice: parseFloat(ticket.price).toFixed(2),
            voucherCode: '',
            voucherDiscount: '',
            currency,
            taxRate: '0.00',
            shipping: '0.00',
            shippingMethod: '',
            priceWithTax: parseFloat(ticket.price).toFixed(2),
            subTotal: (parseFloat(ticket.price) * parseInt(ticket.qty)).toFixed(2),
          },
        })
        if(ticket.tags.includes('rooms')){
          ticket.attributes.forEach(att => {
            if (att.attribute_id.match(/-48$/)) adultsQty.push(parseInt(att.values[0]))
            if (att.attribute_id.match(/-49$/)) childrenQty.push(parseInt(att.values[0]))
            if (att.attribute_id.match(/-87$/)) infantsQty.push(parseInt(att.values[0]))
          })
        }
      })

      const isItHotelOrder = order.order_tickets.some(ticket => ticket.tags.includes('rooms'))

      if(isItHotelOrder){
        transaction.totalGuests = `Adults: ${adultsQty.reduce((total,qty) => total + qty,0)}, Children: ${(childrenQty.reduce((total,qty) => total + qty,0) + infantsQty.reduce((total,qty) => total + qty,0))}`
      }

      window.digitalData.transaction = transaction

      // Functionality to only log purchase event once, even if component is rendered again
      let logEvent = true;
      window.dataLayer.forEach((dataLayer: any) => {
        if(dataLayer.ecommerce){
          logEvent = false
        }
      })
      if (logEvent) {
        googleDataLayerApi.logPurchaseEvent(order)
        orderStatusDataLayerApi.logOrderStatusEvent(order)
      }
    }

    // Add details of A/B tests to data layer:
    abContext.allTests.forEach(test => {
      if (!window.dataLayer.some((event: any) => event?.test?.name === test.name)) {
        window.dataLayer.push({
          event: "abtest",
          test: {
            name: test.name,
            group: test.active ? "variant" : "control"
          }
        })
      }
    })

    window.digitalData.page = page
    window.digitalData.platform = platform
    window.digitalData.products = products
    window.digitalData.version = '1.0' // W3C specification version

    {/* console.log('digitalData', JSON.stringify(window.digitalData, undefined, 2)) */}

  }, [ pageName, subCategory1, subCategory2, subCategory3, productsOnPage, dataLayerContext, order, hotelsOnPage, roomsOnPage, error, abContext ])


  // Update digitalData.user when session loads:
  useEffect(() => {

    if (sessionContext.session && !sessionContext.session.loading) {
      window.digitalData = window.digitalData || {};

      const visitData: VisitsData | undefined = dataLayerApi.getVisitData()

      const user = [
        {
          isAuthenticated: sessionContext.session.login_order_id ? true : false,

          daysSinceLastVisit: visitData?.daysSinceLastVisit,
          newOrReturning30Days: visitData?.daysSinceLastVisit === undefined ? '' : visitData.daysSinceLastVisit < 30 ? 'returning' : 'new',
          newOrReturning90Days: visitData?.daysSinceLastVisit === undefined ? '' : visitData.daysSinceLastVisit < 90 ? 'returning' : 'new',
          profile: [
            {
              profileInfo: {
                language,
                returningStatus: visitData?.hasLoggedInBefore,
                type: 'Consumer',
              }
            }
          ]
        }
      ];

      window.digitalData.user = user
    }
  }, [ sessionContext ])


  // Update digitalData.cart whenever cart changes:
  useEffect(() => {

    if (cartContext.cart && !cartContext.cart.loading) {

      window.digitalData = window.digitalData || {};

      const cart: any = {
        cartID: cartContext.cart.data.id,
        price: {
          basePrice: cartContext.cart.meta.total_price,
          voucherCode: '', // empty for now
          voucherDiscount: '', // empty for now
          currency,
          taxRate: '0.00',
          shipping: '0.00',
          shippingMethod: '',
          priceWithTax: cartContext.cart.meta.total_price, // "basePrice + (basePrice*(1+taxRate))"
          cartTotal: cartContext.cart.meta.total_price, // "Amount of total cart after taxes, discounts, and shipping (e.g. $155.99)"
        },
        attributes: {
          totalQty: cartContext.cart.meta.total_count, // "Total number of items in entire cart (e.g. 5)"
          totalViews: dataLayerApi.getNumCartViews(),
          lastViewed: dataLayerApi.getLastCartViewDate()?.toISOString() ?? '',
          deliveryMethod: '',
        },
        item: [],
      }
      cartContext.cart.data.tickets.forEach((cartItem,index) => {

        let title = cartItem.title
        const tmp = []
        if (cartItem.data.orig_type_ticket) tmp.push(cartItem.data.orig_type_ticket)
        if (cartItem.data.year) tmp.push(cartItem.data.year)
        if (tmp.length) title = title + ' (' + tmp.join(', ') + ')'

        // Get attribute ids for this cart item. Work around an API bug whereby the
        // "attributes" property is an object unless it's empty, in which case it's an
        // empty array.
        let attributeIds: string[] = []
        if (!Array.isArray(cartItem.attributes)) {
          attributeIds = Object.getOwnPropertyNames(cartItem.attributes).map(id => id.toString())
        }

        let year = cartItem.year
        if (!year && cartItem.date) year = cartItem.date.substring(0, 4)

        if (cartItem.tags.includes('hotels')) {
          const roomId = Object.keys(cartItem.data.room.packages)[0];
          const roomDeatils = cartItem.data.room.packages[roomId];

          cart.item.push({
            productInfo: {
              productID: "Hotels | " + title,
              productName: cartItem.data.attraction_title,
              description: '', // Empty for now
              productURL: '', // Empty for now
              productImage: '', // Empty for now
              productThumbnail: '', // Empty for now
              manufacturer: '', // Empty for now
              sku: roomDeatils.sku,
              plu: '', // Empty for now
              source: 'UK Store',
              totalGuests: 'Adults: ' + cartItem.attributes['48'] + `, Children: ${cartItem.attributes['49'][0] + cartItem.attributes['87'][0]}`,
              promo: 0,
            },
            category: {
              primaryCategory: dataLayerApi.getTicketPrimaryCategory(cartItem.title, attributeIds),
            },
            attributes: {
              packageID: '', // Empty for now
              packageInstanceID: '', // Empty for now
              productAttributes: {}, // Empty for now
              dateSelectedIn: cartItem.date,
              dateSelectedOut: (42 in cartItem.attributes ? cartItem.attributes[42][0] : ''),
              dateSelectedRange: (cartItem.date && 42 in cartItem.attributes) ? differenceInDays(parseISO(cartItem.attributes[42][0]), parseISO(cartItem.date)).toString() : '',
              Qty: cartItem.qty,
              YearSelected: year,
            },
            price: {
              basePrice: parseFloat(cartItem.price).toFixed(2),
              voucherCode: '',
              voucherDiscount: '',
              currency,
              taxRate: '0.00',
              shipping: '0.00',
              shippingMethod: '',
              priceWithTax: parseFloat(cartItem.price).toFixed(2),
              subTotal: (parseFloat(cartItem.price) * cartItem.qty).toFixed(2),
            }
          });
        }
        else {
          cart.item.push({
            productInfo: {
              productID: cartItem.ticket_id,
              productName: title,
              description: '', // Empty for now
              productURL: '', // Empty for now
              productImage: '', // Empty for now
              productThumbnail: '', // Empty for now
              manufacturer: '', // Empty for now
              sku: cartItem.data.model,
              plu: '', // Empty for now
              source: 'UK Store',
              promo: 0,
            },
            category: {
              primaryCategory: dataLayerApi.getTicketPrimaryCategory(cartItem.title, attributeIds),
            },
            attributes: {
              packageID: '', // Empty for now
              packageInstanceID: '', // Empty for now
              productAttributes: {}, // Empty for now
              Qty: cartItem.qty,
              YearSelected: year,
            },
            price: {
              basePrice: parseFloat(cartItem.price).toFixed(2),
              voucherCode: '',
              voucherDiscount: '',
              currency,
              taxRate: '0.00',
              shipping: '0.00',
              shippingMethod: '',
              priceWithTax: parseFloat(cartItem.price).toFixed(2),
              subTotal: (parseFloat(cartItem.price) * cartItem.qty).toFixed(2),
            }
          });
        }
      })
      // pageLoadEvent is called here as it has to load as a last thing on the page.
      // Universal based on it some data layer functionality and this is what they required.
      dataLayerApi.pageLoadEvent(pageName);
      window.digitalData.cart = cart
    }

  }, [ dataLayerContext, cartContext, pageName ])

  return null
}
